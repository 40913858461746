/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./home-carousel.css"

const Viewer = props => {
  return (
    <div
      sx={{
        position: "relative",
        height: ["40vh", "calc(88vh - 60px)"],
        maxHeight: "900px",
      }}
    >
      <Img
        // {...props}
        //loading={'eager'}
        fluid={props.data.Image_optimized.childImageSharp.fluid}
        placeholderStyle={{ opacity: 1, filter: "blur(10px)" }}
        sx={{
          height: ["40vh", "calc(88vh - 60px)"],
          maxHeight: "900px",
        }}
      />
      <div
        sx={{
          // backgroundColor: "rgba(0, 0, 0, 0.4)",
          position: "absolute",
          width: "100%",
          bottom: "0px",
          left: 0,
          height: "100%",
          background:
            "linear-gradient(0deg, rgba(0,0,0,.7), rgba(255,0,0,0) 60%)",
          display: "flex",
          justifyContent: "left",
          alignItems: "flex-end",
        }}
      >
        <div sx={{ my: 3, ml: 4 }}>
          <h2
            sx={{
              textAlign: "left",
              color: "#FFF",
              my: 0,
              fontSize: "3em",
              fontWeight: "600",
              opacity: 0.9,
            }}
          >
            {props.data.Titre_FR}
          </h2>
          <p
            sx={{
              textAlign: "left",
              color: "#FFF",
              mt: 0,
              mb: "20px",
              fontSize: "1.3em",
              maxWidth: "700px",
              mx: "auto",
              opacity: 0.9,
            }}
          >
            {props.data.Description_FR}
          </p>
        </div>
      </div>
    </div>
  )
}

export default props => {
  const sliderSettings = {
    lazyLoad: false,
    accessibility: true,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    fade: true,
    speed: 1500,
    cssEase: "ease-in-out",
    autoplay: true,
    autoplaySpeed: 6000,
  }
  return (
    <Slider sx={{ mx: 0, my: 0 }} {...sliderSettings}>
      {props.slideshow &&
        props.slideshow.map(slide => <Viewer key={slide.id} data={slide} />)}
    </Slider>
  )
}
